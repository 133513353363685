import React, { useEffect, useState } from 'react';
import Particles from './components/Particles';
import MinimalButton from './components/MinimalButton';
import BurgerMenu from './components/BurgerMenu';
import { initSmoothScroll } from './components/smoothScroll';
import './styles/App.css';

function App() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    initSmoothScroll();
    
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      if (newIsMobile) {
        // Reset button hover state on mobile
        setIsButtonHovered(false);
      }
    };

    const handleMouseMove = (event) => {
      if (!isMobile) {
        setCursorPosition({ x: event.clientX, y: event.clientY });
      }
    };

    handleResize(); // Call once to set initial state
    window.addEventListener('resize', handleResize);
    
    if (!isMobile) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMobile]);

  return (
    <div className="App transparent-bg">
      <Particles 
        isButtonHovered={isButtonHovered} 
        cursorPosition={cursorPosition}
        isMobile={isMobile}
      />
      {isMobile ? (
        <BurgerMenu />
      ) : (
        <div className="header">
          <img src="/supersub_logo.webp" alt="SuperSub Logo" className="logo" />
          <MinimalButton 
            href="https://app.supersub.ai"
            onMouseEnter={() => !isMobile && setIsButtonHovered(true)}
            onMouseLeave={() => !isMobile && setIsButtonHovered(false)}
          />
        </div>
      )}
      <div className="fullpage-container">
        <section className="section" id="hero">
          <div>
            <h1>Supersub.AI</h1>
            <p>Propulsez vos projets associatifs vers le succès avec l'intelligence artificielle</p>
            <button className="btn">Boostez vos candidatures</button>
          </div>
        </section>
        <section className="section" id="features">
          <div>
            <h2>Fonctionnalités révolutionnaires</h2>
            <div className="feature-container">
              <div className="feature-box">
                <h3>Veille intelligente</h3>
                <p>Recevez des alertes sur mesure pour les appels à projets correspondant à votre association</p>
              </div>
              <div className="feature-box">
                <h3>Rédaction assistée</h3>
                <p>Générez des dossiers de candidature complets en un clic grâce à notre IA experte</p>
              </div>
              <div className="feature-box">
                <h3>Suivi stratégique</h3>
                <p>Visualisez et optimisez vos candidatures avec notre dashboard intuitif</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section" id="how-it-works">
          <div>
            <h2>Simplifiez vos démarches</h2>
            <p>Supersub.AI automatise la veille, la rédaction et le suivi de vos candidatures, vous permettant de vous concentrer sur l'essentiel : votre mission associative.</p>
            <button className="btn">Essayez gratuitement</button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;