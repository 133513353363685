import React, { useState, useEffect } from 'react';
import MinimalButton from './MinimalButton';
import '../styles/BurgerMenu.css';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.keyCode === 27 && isOpen) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleEscKey);
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]);

  return (
    <>
      <div className="burger-menu">
        <button 
          className={`burger-icon ${isOpen ? 'open' : ''}`} 
          onClick={toggleMenu}
          aria-label={isOpen ? "Fermer le menu" : "Ouvrir le menu"}
          aria-expanded={isOpen}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <div className={`menu-content ${isOpen ? 'open' : ''}`}>
        <div className="menu-inner">
          <div className="menu-items">
            <img src="/supersub_logo.webp" alt="SuperSub Logo" className="logo" />
            <nav>
              <MinimalButton href="https://app.supersub.ai" className="menu-button" />
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default BurgerMenu;