export function initSmoothScroll() {
    const sections = document.querySelectorAll('.section');
    let currentSection = 0;
    let isScrolling = false;
    const scrollThreshold = 15;
  
    function scrollToSection(index) {
      if (index >= 0 && index < sections.length && !isScrolling) {
        isScrolling = true;
        sections[index].scrollIntoView({ behavior: 'smooth' });
        currentSection = index;
        setTimeout(() => {
          isScrolling = false;
        }, 1000);
      }
    }
  
    window.addEventListener('wheel', (e) => {
      e.preventDefault();
      if (Math.abs(e.deltaY) > scrollThreshold) {
        if (e.deltaY > 0 && currentSection < sections.length - 1) {
          scrollToSection(currentSection + 1);
        } else if (e.deltaY < 0 && currentSection > 0) {
          scrollToSection(currentSection - 1);
        }
      }
    }, { passive: false });
  
    // Ajoutez ici la gestion des événements tactiles et des touches fléchées si nécessaire
  }