import React from 'react';
import '../styles/MinimalButton.css';

const MinimalButton = ({ onMouseEnter, onMouseLeave, href }) => {
    return (
      <a 
        href={href}
        className="metallic-text-button-5 attract-button"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ textDecoration: 'none', display: 'inline-block' }}
      >
        <span>Connexion</span>
      </a>
    );
  };

export default MinimalButton;