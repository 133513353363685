import React, { useEffect, useRef, useState } from 'react';

const particleSpeed = 0.3;
const particleMaxSpeed = 0.7;
const repulseForce = 0.08;
const attractRadius = 800;
const attractForce = 0.013;

const isMobileOrTablet = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth <= 1024;
};

const Particles = ({ isButtonHovered, cursorPosition }) => {
  const [particleCount, setParticleCount] = useState(500); // Default value
  const canvasRef = useRef(null);
  const particlesRef = useRef([]);
  const isButtonHoveredRef = useRef(isButtonHovered);
  const cursorPositionRef = useRef(cursorPosition);
  const isMobileOrTabletRef = useRef(isMobileOrTablet());

  useEffect(() => {
    isButtonHoveredRef.current = isButtonHovered;
  }, [isButtonHovered]);
  
  useEffect(() => {
    cursorPositionRef.current = cursorPosition;
  }, [cursorPosition]);

  useEffect(() => {
    const fetchParticleCount = async () => {
      try {
        const response = await fetch('https://steward.supersub.ai/call_to_project_count', {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Authorization': 'Bearer data_steward_supersub_ai',
          }
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("API response:", data);
        if (data && typeof data.nb_ctp === 'number') {
          setParticleCount(data.nb_ctp);
        } else {
          console.error('Unexpected API response format:', data);
        }
      } catch (error) {
        console.error('Error fetching particle count:', error.message);
      }
    };

    fetchParticleCount();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const mouse = { x: null, y: null, radius: 100 };

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 0.5;
        this.speedX = (Math.random() - 0.5) * particleSpeed;
        this.speedY = (Math.random() - 0.5) * particleSpeed;
        this.color = `rgba(30, 58, 255, ${Math.random() * 0.3 + 0.2})`;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
  
        // Effet de bord infini
        if (this.x < 0) this.x = canvas.width;
        if (this.x > canvas.width) this.x = 0;
        if (this.y < 0) this.y = canvas.height;
        if (this.y > canvas.height) this.y = 0;
  
        // Effet répulsif de la souris et effet d'attraction (seulement sur desktop)
        if (!isMobileOrTabletRef.current) {
          const dx = mouse.x - this.x;
          const dy = mouse.y - this.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < mouse.radius) {
            const angle = Math.atan2(dy, dx);
            const force = (mouse.radius - distance) / mouse.radius;
            this.speedX -= force * Math.cos(angle) * repulseForce;
            this.speedY -= force * Math.sin(angle) * repulseForce;
          }
        }
  
        // Effet d'attraction vers le curseur quand le bouton est survolé
        if (isButtonHoveredRef.current && !isMobileOrTabletRef.current) {
          const dxAttract = cursorPositionRef.current.x - this.x;
          const dyAttract = cursorPositionRef.current.y - this.y;
          const distanceAttract = Math.sqrt(dxAttract * dxAttract + dyAttract * dyAttract);
          if (distanceAttract < attractRadius) {
            const angleAttract = Math.atan2(dyAttract, dxAttract);
            const forceAttract = (attractRadius - distanceAttract) / attractRadius;
            this.speedX += forceAttract * Math.cos(angleAttract) * attractForce;
            this.speedY += forceAttract * Math.sin(angleAttract) * attractForce;
          }
        }
  
        // Limitation de la vitesse
        const speed = Math.sqrt(this.speedX * this.speedX + this.speedY * this.speedY);
        if (speed > particleMaxSpeed) {
          this.speedX = (this.speedX / speed) * particleMaxSpeed;
          this.speedY = (this.speedY / speed) * particleMaxSpeed;
        }
      }

      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function init() {
      particlesRef.current = [];
      for (let i = 0; i < particleCount; i++) {
        particlesRef.current.push(new Particle());
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < particlesRef.current.length; i++) {
        particlesRef.current[i].update();
        particlesRef.current[i].draw();
      }
      animationFrameId = requestAnimationFrame(animate);
    }

    init();
    animate();

    const handleMouseMove = (event) => {
      mouse.x = event.x;
      mouse.y = event.y;
      cursorPositionRef.current = { x: event.x, y: event.y };
    };

    const handleResize = () => {
      const oldWidth = canvas.width;
      const oldHeight = canvas.height;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const scaleX = canvas.width / oldWidth;
      const scaleY = canvas.height / oldHeight;
      
      particlesRef.current.forEach(particle => {
        particle.x *= scaleX;
        particle.y *= scaleY;
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, [particleCount]);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default Particles;